<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="close"
  >
    <template slot="title">
      Schedule Form
    </template>
    <template slot="body">
      <div class="md-layout">
        <div
          v-if="!item"
          class="md-layout-item"
        >
          <TeachersSelect
            v-if="!user.is_teacher"
            v-model="form.teacher_id"
          />

          <StudentsAutocomplete
            v-model="form.student_id"
            label="Student"
          />
        </div>
        <template v-else>
          <div
            v-for="(c,i) of questions"
            :key="i"
            class="md-layout-item md-size-100"
          >
            <p class="mb-1 mt-4">
              <b>{{ c[0].category.title }}</b>
            </p>
            <table class="table table-striped">
              <tr>
                <th style="width: 70%">
                  Learners can...
                </th>
                <th class="text-center">
                  1
                </th>
                <th class="text-center">
                  2
                </th>
                <th class="text-center">
                  3
                </th>
                <th class="text-center">
                  4
                </th>
                <th class="text-center">
                  5
                </th>
              </tr>
              <tr
                v-for="a of c"
                :key="a.per_schedule_answer_id"
              >
                <td>{{ a.title }}</td>
                <td class="text-center">
                  <input
                    v-model="updateForm.answers[a.per_schedule_answer_id].score"
                    type="radio"
                    :name="a.per_schedule_answer_id"
                    value="1"
                    :disabled="!editable"
                  >
                </td>
                <td class="text-center">
                  <input
                    v-model="updateForm.answers[a.per_schedule_answer_id].score"
                    type="radio"
                    :name="a.per_schedule_answer_id"
                    value="2"
                    :disabled="!editable"
                  >
                </td>
                <td class="text-center">
                  <input
                    v-model="updateForm.answers[a.per_schedule_answer_id].score"
                    type="radio"
                    :name="a.per_schedule_answer_id"
                    value="3"
                    :disabled="!editable"
                  >
                </td>
                <td class="text-center">
                  <input
                    v-model="updateForm.answers[a.per_schedule_answer_id].score"
                    type="radio"
                    :name="a.per_schedule_answer_id"
                    value="4"
                    :disabled="!editable"
                  >
                </td>
                <td class="text-center">
                  <input
                    v-model="updateForm.answers[a.per_schedule_answer_id].score"
                    type="radio"
                    :name="a.per_schedule_answer_id"
                    value="5"
                    :disabled="!editable"
                  >
                </td>
              </tr>
            </table>
          </div>
          <div class="md-layout-item md-size-100">
            <FormTextarea
              v-model="updateForm.comments"
              label="Comments"
              :disabled="!editable"
            />
          </div>
        </template>
      </div>
    </template>
    <template slot="footer">
      <md-button
        v-if="editable"
        class="md-primary pull-right"
        @click="saveForm"
      >
        <md-icon class="mr-2">
          save
        </md-icon>
        Save
      </md-button>
    </template>
  </vue-modal>
</template>

<script>
import { FormTextarea } from '@/components/Inputs';
import { TeachersSelect, StudentsAutocomplete } from '@/components/Inputs/selects';
import groupBy from '@/utils/groupBy';

export default {
  components: {
    StudentsAutocomplete,
    TeachersSelect,
    FormTextarea,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {
        teacher_id: null,
        student_id: null,
      },
      updateForm: {
        comments: null,
        answers: [],
      },
      questions: [],
      loading: false,
      editable: true,
    };
  },
  mounted() {
    if (this.item) {
      this.getScheduleDetails();

      this.editable = this.item.status !== 'Complete';
    }

    if (this.user.is_teacher) {
      this.form.teacher_id = this.user.user_id;
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    getScheduleDetails() {
      this.request(`per_schedules/${this.item.per_schedule_id}`, 'get', null, ({ data }) => {
        this.questions = groupBy(data.answers, 'category_id');

        this.updateForm.comments = data.comments;
        this.updateForm.answers = data.answers.reduce((a, b) => {
          a[b.per_schedule_answer_id] = {
            id: b.per_schedule_answer_id,
            score: b.score,
          };
          return a;
        }, {});
      });
    },
    saveForm() {
      if (this.item) {
        let valid = true;

        for (const a of Object.values(this.updateForm.answers)) {
          if (!a.score) {
            valid = false;
          }
        }

        if (!valid) {
          this.fireError('Fill all the answers score.');
        }

        if (valid) {
          this.loading = true;
          this.request(`per_schedules/${this.item.per_schedule_id}`, 'put', {
            comments: this.updateForm.comments,
            answers: Object.values(this.updateForm.answers),
          }, (data) => {
            this.fireSuccess(data);
            this.$emit('onSave');
            this.$refs.modal.close();
          }, () => {
            this.loading = false;
          });
        }
      } else {
        const errors = [];
        // Validation
        if (!this.form.student_id) {
          errors.push('Student is required');
        }
        if (!this.form.teacher_id) {
          errors.push('Teacher is required');
        }

        if (errors.length) {
          errors.forEach((x) => {
            this.fireError(x);
          });
          return;
        }

        this.loading = true;
        this.request('per_schedules', 'post', this.form, (data) => {
          this.fireSuccess(data);
          this.$emit('onSave');
          this.$refs.modal.close();
        }, () => {
          this.loading = false;
        });
      }
    },
  },
};
</script>
<style></style>
