<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-content>
        <div class="md-layout">
          <div
            v-if="!user.is_teacher"
            class="md-layout-item md-size-25"
          >
            <TeachersSelect
              v-model="filters.teacher_id"
              all-option="All"
            />
          </div>
          <div
            class="md-layout-item"
            :class="{
              'md-size-25': !user.is_teacher,
              'md-size-33': user.is_teacher,
            }"
          >
            <ClassroomsSelect
              v-model="filters.classroom_id"
              all-option="All"
            />
          </div>
          <div
            class="md-layout-item"
            :class="{
              'md-size-25': !user.is_teacher,
              'md-size-33': user.is_teacher,
            }"
          >
            <FormSelect
              v-model="filters.status"
              :options="statuses"
              label="Status"
              icon="donut_large"
            />
          </div>
          <div
            class="md-layout-item"
            :class="{
              'md-size-25': !user.is_teacher,
              'md-size-33': user.is_teacher,
            }"
          >
            <LevelsSelect
              v-model="filters.level_id"
              all-option="All"
            />
          </div>
          <div class="md-layout-item md-size-50">
            <FormDate
              v-model="filters.from"
              label="Course Finish Date From..."
            />
          </div>
          <div class="md-layout-item md-size-50">
            <FormDate
              v-model="filters.to"
              label="Course Finish Date To..."
            />
          </div>

          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
          <div class="md-layout-item md-size-100">
            <md-button
              class="md-just-icon md-round md-primary pull-right"
              @click="newSchedule"
            >
              <md-icon>add</md-icon>
              <md-tooltip>New Schedule</md-tooltip>
            </md-button>

            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass: 'table',
              }"
              @onShowDetails="onShowDetails"
              @onDelete="onDelete"
              @changed="getSchedules"
              @onResendEmail="onResendEmail"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>

    <SchedulesForm
      v-if="showForm"
      :item="selSchedule"
      @close="closeForm"
      @onSave="initTable"
    />
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { TeachersSelect, LevelsSelect, ClassroomsSelect } from '@/components/Inputs/selects';
import { FormSelect, FormDate } from '@/components/Inputs';
import SchedulesForm from './SchedulesForm.vue';

export default {
  components: {
    VueTable,
    SchedulesForm,
    TeachersSelect,
    LevelsSelect,
    FormSelect,
    ClassroomsSelect,
    FormDate,
  },
  props: [],
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'status',
            sortable: true,
          },
          {
            title: 'teacher.name',
            mask: 'teacher',
            sortable: true,
            sort_value: 'teacher.first_name',
          },
          {
            title: 'classroom.classroom_code',
            mask: 'classroom',
            sortable: true,
          },
          {
            title: 'student.student_number',
            mask: 'student id',
            sortable: true,
          },
          {
            title: 'student.full_name',
            mask: 'student',
            sortable: true,
            sort_value: 'student.first_name',
          },
          {
            title: 'level.name',
            mask: 'level',
            sortable: true,
          },
          {
            title: 'course_finish_date',
            mask: 'course finish date',
            sortable: true,
            dateFormat: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-primary',
            tooltip: 'Resend Results Email',
            callback: 'onResendEmail',
            icon: 'email',
          },
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete Schedule',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      filters: {
        status: 'Incomplete',
        teacher_id: 'all',
        level_id: 'all',
        classroom_id: 'all',
        to: null,
        from: null,
      },
      statuses: [
        { id: 'Incomplete', name: 'Incomplete' },
        { id: 'Complete', name: 'Complete' },
        { id: 'Deleted', name: 'Deleted' },
      ],
      showForm: false,
      selSchedule: null,
      loading: false,
    };
  },
  mounted() {
    this.initTable();
  },
  methods: {
    getSchedules(params) {
      this.loading = true;
      this.request('per_schedules', 'get', {
        page: 1,
        per_page: 25,
        ...params,
        ...this.filters,
        show_deleted: this.filters.status === 'Deleted',
      }, ({ data }) => {
        this.vTable.values = data;
        this.vTable.values.data = this.vTable.values.data.map((x) => {
          x.onResendEmail = x.status === 'Complete';
          return x;
        });
      }, () => {
        this.loading = false;
      });
    },
    onShowDetails(item) {
      this.selSchedule = { ...item };
      this.showForm = true;
    },
    onDelete(item) {
      this.loading = true;
      this.request(`per_schedules/${item.per_schedule_id}`, 'delete', null, (data) => {
        this.initTable();
        this.fireSuccess(data);
      }, () => {
        this.loading = false;
      });
    },
    onResendEmail(item) {
      this.loading = true;
      this.request(`per_schedules/${item.per_schedule_id}/resend`, 'post', null, (data) => {
        this.fireSuccess(data);
      }, () => {
        this.loading = false;
      });
    },
    onFilter() {
      this.getSchedules();
    },
    initTable() {
      this.$refs.vtable.init();
    },
    newSchedule() {
      this.showForm = true;
    },
    closeForm() {
      this.showForm = false;
      this.selSchedule = null;
    },
  },
};
</script>

<style scoped></style>
